import React from 'react';
import styled, { css } from 'styled-components';
import Header from './Header';
import Footer from './Footer';

type BaseLayoutPropsType = {
  className?: string;
  children: React.ReactNode;
};

const BaseLayout: React.FC<BaseLayoutPropsType> = ({ className, children }) => (
  <div className={className}>
    <Header />
    <div className="container">{children}</div>
    <Footer />
  </div>
);

const Layout = styled(BaseLayout)`
  ${() => {
    return css`
      & {
      }
    `;
  }}
`;

export default React.memo(Layout);
