import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import {
  Box,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import CelebrationIcon from '@mui/icons-material/Celebration';
import CakeIcon from '@mui/icons-material/Cake';
import { MENU_LIST } from '../const/const';

type BaseHeaderDrawerPropsType = {
  className?: string;
};

const BaseHeaderDrawer: React.FC<BaseHeaderDrawerPropsType> = ({ className }) => {
  return (
    <div className={className}>
      <List sx={{ width: 300 }} subheader={<ListSubheader>撮影シーンでえらぶ</ListSubheader>}>
        {React.Children.toArray(
          MENU_LIST.map((item, index) => (
            <Link to={item.url}>
              <ListItem button>
                <ListItemIcon>{index % 2 === 0 ? <CelebrationIcon /> : <CakeIcon />}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItem>
            </Link>
          )),
        )}
      </List>
      <Divider />
      {/*
      <List sx={{ width: 300 }} subheader={<ListSubheader>撮影シーンでえらぶ</ListSubheader>}>
        {React.Children.toArray(
          MENU_LIST.map((item, index) => (
            <Link to={item.url}>
              <ListItem button>
                <ListItemIcon>{index % 2 === 0 ? <CelebrationIcon /> : <CakeIcon />}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItem>
            </Link>
          )),
        )}
      </List>
         */}
    </div>
  );
};

const HeaderDrawer = styled(BaseHeaderDrawer)`
  ${() => {
    return css`
      & {
        a {
          color: grey;
          text-decoration: none;
          :hover {
            color: light-grey;
          }
        }
        li.MuiListSubheader-root {
          color: #fff;
          background-color: rgb(87, 139, 118);
        }
      }
    `;
  }}
`;

export default React.memo(HeaderDrawer);
