import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { MENU_LIST } from '../const/const';

type BaseFooterPropsType = {
  className?: string;
};

const BaseFooter: React.FC<BaseFooterPropsType> = ({ className }) => (
  <div className={className}>
    <footer>
      <div className="wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <span>撮影メニュー</span>
            </div>
          </div>
          <div className="spacer30"></div>
          <div className="row">
            {React.Children.toArray(
              MENU_LIST.map((item, index) => (
                <div className="col-6">
                  <Link to={item.url}>{item.name}</Link>
                </div>
              )),
            )}
          </div>
          <div className="spacer30"></div>
          <div className="row">
            <div className="col-12 text-center">
              <span>copyright 2021 早川写真</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
);

const Footer = styled(BaseFooter)`
  ${() => {
    return css`
      & {
        margin-top: 20px;
        .spacer30 {
          margin-bottom: 30px;
        }
        .wrapper {
          margin: 0;
          padding: 40px;
          background: rgb(87, 139, 118);
        }
        a {
          font-size: 13px;
          color: white;
          text-decoration: none;
        }
        span {
          align-content: center;
          align-items: center;
          text-align: center;
          font-weight: 700;
          font-size: 13px;
          color: white;
        }
        .text-center {
          text-align: center;
          margin: 30px auto;
        }
        .col-6 {
          padding: 12px 0px;
        }
      }
    `;
  }}
`;

export default React.memo(Footer);
