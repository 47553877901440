
import React from 'react';
type Option = {
  label: React.ReactNode;
  value: string | number | string[];
  price?: number;
  title?: string;
};

export const MENU_LIST = [
  {
    name: 'フォト婚洋和装',
    url: '/wedding/',
  },
  {
    name: 'フォト婚洋装',
    url: '/wedding/',
  },
  {
    name: '成人式',
    url: '/seijinshiki/',
  },
  {
    name: '七五三',
    url: '/shichigosan/',
  },
  {
    name: 'お宮参り',
    url: '/omiyamairi/',
  },
  {
    name: '百日祝い',
    url: '/omiyamairi/',
  },
  {
    name: 'ご入園',
    url: '/entree/',
  },
  {
    name: 'ご入学',
    url: '/entree/',
  },
  {
    name: 'お誕生日',
    url: '/birthday/',
  },
  {
    name: 'バースデー',
    url: '/birthday/',
  },
];
export const SESISON_MONTH_LIST: Option[] = [
  { label: '1月', value: '1' },
  { label: '2月', value: '2' },
  { label: '3月', value: '3' },
  { label: '4月', value: '4' },
  { label: '5月', value: '5' },
  { label: '6月', value: '6' },
  { label: '7月', value: '7' },
  { label: '8月', value: '8' },
  { label: '9月', value: '9' },
  { label: '10月', value: '10' },
  { label: '11月', value: '11' },
  { label: '12月', value: '12' },
];

export const SESISON_DAY_LIST: Option[] = [
  { label: '1日', value: '1' },
  { label: '2日', value: '2' },
  { label: '3日', value: '3' },
  { label: '4日', value: '4' },
  { label: '5日', value: '5' },
  { label: '6日', value: '6' },
  { label: '7日', value: '7' },
  { label: '8日', value: '8' },
  { label: '9日', value: '9' },
  { label: '10日', value: '10' },
  { label: '11日', value: '11' },
  { label: '12日', value: '12' },
  { label: '13日', value: '13' },
  { label: '14日', value: '14' },
  { label: '15日', value: '15' },
  { label: '16日', value: '16' },
  { label: '17日', value: '17' },
  { label: '18日', value: '18' },
  { label: '19日', value: '19' },
  { label: '20日', value: '20' },
  { label: '21日', value: '21' },
  { label: '22日', value: '22' },
  { label: '23日', value: '23' },
  { label: '24日', value: '24' },
  { label: '25日', value: '25' },
  { label: '26日', value: '26' },
  { label: '27日', value: '27' },
  { label: '28日', value: '28' },
  { label: '29日', value: '29' },
  { label: '30日', value: '30' },
  { label: '31日', value: '31' },
];

export const SESISON_TIME_LIST: Option[] = [
  { label: '10:00', value: '10' },
  { label: '11:00', value: '11' },
  { label: '12:00', value: '12' },
  { label: '13:00', value: '13' },
  { label: '14:00', value: '14' },
  { label: '15:00', value: '15' },
  { label: '16:00', value: '16' },
];

// お住まいは
export const ADDRESS_LIST: Option[] = [
  { label: '佐渡市小木', value: '佐渡市小木' },
  { label: '佐渡市羽茂', value: '佐渡市羽茂' },
  { label: '佐渡市赤泊', value: '佐渡市赤泊' },
  { label: '佐渡市金井', value: '佐渡市金井' },
  { label: '佐渡市両津', value: '佐渡市両津' },
  { label: '佐渡市佐和田', value: '佐渡市佐和田' },
  { label: '佐渡市相川', value: '佐渡市相川' },
  { label: '佐渡市真野', value: '佐渡市真野' },
  { label: '佐渡市畑野', value: '佐渡市畑野' },
  { label: '佐渡市新穂', value: '佐渡市新穂' },
  { label: '佐渡島外から', value: '佐渡島外から' }
];

// 性別
export const GENDER_LIST: Option[] = [
  { label: '男の子', value: '男の子' },
  { label: '女の子', value: '女の子' },
];

export const AGE_LIST: Option[] = [
  { label: '1歳 (撮影料 5,500円)', value: '1歳 (撮影料 5,500円)', price: 5500 },
  { label: '2歳 (撮影料 5,500円)', value: '2歳 (撮影料 5,500円)', price: 5500 },
  { label: '3歳 (撮影料 5,500円)', value: '3歳 (撮影料 5,500円)', price: 5500 },
  { label: '4歳 (撮影料 5,500円)', value: '4歳 (撮影料 5,500円)', price: 5500 },
  { label: '5歳 (撮影料 5,500円)', value: '5歳 (撮影料 5,500円)', price: 5500 },
  { label: '6歳 (撮影料 5,500円)', value: '6歳 (撮影料 5,500円)', price: 5500 },
  { label: '7歳 (撮影料 5,500円)', value: '7歳 (撮影料 5,500円)', price: 5500 },
  { label: '8歳 (撮影料 5,500円)', value: '8歳 (撮影料 5,500円)', price: 5500 },
  { label: '9歳 (撮影料 5,500円)', value: '9歳 (撮影料 5,500円)', price: 5500 },
  { label: '10歳 (撮影料 5,500円)', value: '10歳 (撮影料 5,500円)', price: 5500 },
  { label: '11歳 (撮影料 5,500円)', value: '11歳 (撮影料 5,500円)', price: 5500 },
  { label: '12歳 (撮影料 5,500円)', value: '12歳 (撮影料 5,500円)', price: 5500 },
  { label: '13歳 (撮影料 5,500円)', value: '13歳 (撮影料 5,500円)', price: 5500 },
];

// '撮影するお子様'
export const OMIYAMAIRI_LIST: Option[] = [
  { label: '1月生まれ (撮影料 5,500円)', value: '1月生まれ (撮影料 5,500円)', price: 5500 },
  { label: '2月生まれ (撮影料 5,500円)', value: '2月生まれ (撮影料 5,500円)', price: 5500 },
  { label: '3月生まれ (撮影料 5,500円)', value: '3月生まれ (撮影料 5,500円)', price: 5500 },
  { label: '4月生まれ (撮影料 5,500円)', value: '4月生まれ (撮影料 5,500円)', price: 5500 },
  { label: '5月生まれ (撮影料 5,500円)', value: '5月生まれ (撮影料 5,500円)', price: 5500 },
  { label: '6月生まれ (撮影料 5,500円)', value: '6月生まれ (撮影料 5,500円)', price: 5500 },
  { label: '7月生まれ (撮影料 5,500円)', value: '7月生まれ (撮影料 5,500円)', price: 5500 },
  { label: '8月生まれ (撮影料 5,500円)', value: '8月生まれ (撮影料 5,500円)', price: 5500 },
  { label: '9月生まれ (撮影料 5,500円)', value: '9月生まれ (撮影料 5,500円)', price: 5500 },
  { label: '10月生まれ (撮影料 5,500円)', value: '10月生まれ (撮影料 5,500円)', price: 5500 },
  { label: '11月生まれ (撮影料 5,500円)', value: '11月生まれ (撮影料 5,500円)', price: 5500 },
  { label: '12月生まれ (撮影料 5,500円)', value: '12月生まれ (撮影料 5,500円)', price: 5500 },
];

// '撮影するお子様'
export const SHICHIGOSAN_LIST: Option[] = [
  { label: '２・３歳男の子 (撮影料 5,500円)', value: '２・３歳男の子 (撮影料 5,500円)', price: 5500 },
  { label: '２・３歳女の子 (撮影料 5,500円)', value: '２・３歳女の子 (撮影料 5,500円)', price: 5500 },
  { label: '４・５歳男の子 (撮影料 5,500円)', value: '４・５歳男の子 (撮影料 5,500円)', price: 5500 },
  { label: '６・７歳女の子 (撮影料 8,800円)', value: '６・７歳女の子 (撮影料 8,800円)', price: 8800 },
  { label: 'なし', value: 'なし', price: 0 }
];

// 'ごきょうだいと一緒に撮影'
export const BROTHER_LIST: Option[] = [
  { label: '衣装を借りて撮影 (追加 +3,300円)', value: '衣装を借りて撮影 (追加 +3,300円)', price: 3300 },
  { label: '自分の衣装で撮影 (追加 +0円)', value: '自分の衣装で撮影 (追加 +0円)', price: 0 },
  { label: '撮影しない (追加 +0円)', value: '撮影しない (追加 +0円)', price: 0 }
];

// 'ご家族と一緒に撮影'
export const FAMILY_LIST: Option[] = [
  { label: '一緒に撮影する (追加 +3,300円)', value: '一緒に撮影する (追加 +3,300円)', price: 3300 },
  { label: '撮影しない (追加 +0円)', value: '撮影しない (追加 +0円)', price: 0 }
];

// 'おでかけ衣装レンタル'
export const ODEKAKE_LIST: Option[] = [
  { label: '利用する (追加 +11,000円)', value: '利用する (追加 +11,000円)', price: 11000 },
  { label: '利用しない (追加 +0円)', value: '利用しない (追加 +0円)', price: 0 }
];

// '当店のご利用は'
export const REPEATER_LIST: Option[] = [
  { label: '今回初めて', value: '今回初めて' },
  { label: '以前利用した', value: '以前利用した' }
];

export const WEDDING_LIST = [
  { label: '洋装プラン (基本料 88,000円)', value: '洋装プラン (基本料 88,000円)', price: 88000 },
  { label: '和装・洋装プラン (基本料 132,000円)', value: '和装・洋装プラン (基本料 132,000円)', price: 132000 }
];

export const SEIJINSHIKI_LIST = [
  { label: '成人式 振袖・ドレスプラン (基本料 66,000円)', value: '成人式 振袖・ドレスプラン (基本料 66,000円)', price: 66000 },
  { label: '成人式 羽織袴・タキシードプラン (基本料 50,600円)', value: '成人式 羽織袴・タキシードプラン (基本料 50,600円)', price: 50600 }
];

export const SEIJINSHIKI_KIMONO_LIST = [
  { value: '自分の衣装を持ち込む', price: 0 },
  { value: '女性振袖レンタル', price: 33000 },
  { value: '男性羽織袴レンタル', price: 22000 }
]

export const SEIJINSHIKI_KITSUKE_LIST = [
  { value: '利用しない', price: 0 },
  { value: '女性着付け利用', price: 8800 },
  { value: '男性着付け利用', price: 6600 }
]

export const SEIJINSHIKI_HAIR_LIST = [
  { title: 'ヘアセット', value: '利用しない', price: 0 },
  { title: 'ヘアセット', value: '女性ヘアセット利用', price: 4400 },
  { title: 'ヘアセット', value: '男性ヘアセット利用', price: 2200 }
]

export const SEIJINSHIKI_MAKEUP_LIST = [
  { title: 'メイク', value: '利用しない', price: 0 },
  { title: 'メイク', value: '女性メイクを利用', price: 3300 }
]

export const SEIJINSHIKI_SEIJINSHIKI_DRESS_LIST = [
  { title: '撮影用ドレス', value: '利用しない', price: 0 },
  { title: '撮影用ドレス', value: '女性ドレス撮影', price: 33000 }
]
