import { useCallback, useState } from 'react';

const useBoolean = (initialValue: boolean) => {
  const [state, setState] = useState<boolean>(!!initialValue);
  const setTrue = useCallback(() => setState(true), []);
  const setFalse = useCallback(() => setState(false), []);
  const toggle = useCallback(() => setState((prev) => !prev), []);
  return { state, setTrue, setFalse, toggle };
};

export default useBoolean;
